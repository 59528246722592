/** BUTTONS ***/

.emb-button {
  @apply inline-flex h-8 flex-shrink-0 flex-grow-0 items-center justify-center gap-1 rounded-[4px] border px-3 py-2 text-button font-medium transition-all duration-200;

  .mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}

.emb-icon-button {
  @apply flex h-[32px] w-[32px] flex-shrink-0 cursor-pointer items-center justify-center rounded-[4px] border transition-all duration-200;

  .mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}

.emb-signup-button {
  @extend .emb-button;
  @apply h-12 justify-start gap-4 rounded-3xl text-base;
  .mat-icon {
    @apply h-8 w-8;
  }
}

.emb-button-black {
  @apply border-gray-300 bg-gray-300 text-white hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-white {
  @apply border-white bg-white text-gray-300 hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-clear-black {
  @apply border-transparent bg-transparent text-gray-300 hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-clear-white {
  @apply border-transparent bg-transparent text-white hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-green {
  @apply border-green-300 bg-green-300 text-white hover:border-gray-200 hover:bg-gray-200 hover:text-gray-300 disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-red {
  @apply border-red-300 bg-red-300 text-white hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-yellow {
  @apply border-yellow-300 bg-yellow-300 text-gray-300 hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-button-gray {
  @apply border-border-default bg-gray-200 text-gray-300 hover:border-green-300 hover:bg-green-300 hover:text-white disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400-040;
}

.emb-tab-button {
  @apply h-12 w-12 border-b border-b-transparent text-base transition-all duration-200 disabled:opacity-15 hover:border-b hover:border-b-border-default hover:pt-1;
  .mat-icon {
    @apply h-6 w-6;
  }
  &.emb-tab-button-active {
    @apply border-b-green-300 text-green-300;
  }
}

.emb-select-button {
  @apply flex h-auto w-full overflow-hidden items-center justify-center rounded-[4px] border-2 border-gray-400-012 text-base transition-all duration-200 hover:border-border-hover;
  .mat-icon {
    @apply h-full w-full;
  }
  &.emb-select-button-active {
    @apply border-green-300 bg-gray-400-012;
  }
}
