:root {
  /* Light */
  /* colors */

  --color-black: 0 0 0;
  --color-white: 255 255 255;
  --color-primary-100: 98 208 150;
  --color-primary-200: 67 177 119;
  --color-primary-300: 50 152 99;
  --color-secondary-100: 43 159 243;
  --color-secondary-200: 12 140 233;
  --color-secondary-300: 0 124 214;
  --color-accent-100: 255 249 233;
  --color-accent-200: 255 237 192;
  --color-accent-300: 255 228 163;
  --color-dark-100: 130 136 141;
  --color-dark-200: 73 77 81;
  --color-dark-300: 48 51 54;
  --color-light-100: 238 241 244;
  --color-light-200: 222 229 234;
  --color-light-300: 211 219 225;

  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 16px;
  --font-size-lg: 1.25rem;
  --font-size-xl: 2rem;
  --font-size-button: 1rem;

  --line-height-default: '140%';
  --line-height-xs: var(--line-height-default);
  --line-height-sm: var(--line-height-default);
  --line-height-base: var(--line-height-default);
  --line-height-lg: var(--line-height-default);
  --line-height-xl: var(--line-height-default);
  --line-height-button: var(--line-height-default);

  --font-family: 'Onest';

  --border-radius-sm: 4px;
  --border-radius-base: 8px;

  --body-background-color: var(--color-light-100);
  --body-headline-color: var(--color-dark-300);
  --body-text-color: var(--color-dark-100);

  ////////// buttons
  --buttons-primary-background-color: var(--color-primary-200);
  --buttons-primary-background-color-hover: var(--color-primary-300);
  --buttons-primary-label-color: var(--color-white);
  --buttons-primary-label-color-hover: var(--color-white);
  --buttons-secondary-background-color: var(--color-secondary-200);
  --buttons-secondary-background-color-hover: var(--color-secondary-300);
  --buttons-secondary-label-color: var(--color-white);
  --buttons-secondary-label-color-hover: var(--color-white);

  --buttons-accent-background-color: var(--color-accent-200);
  --buttons-accent-background-color-hover: var(--color-accent-300);
  --buttons-accent-label-color: var(--color-dark-300);
  --buttons-accent-label-color-hover: var(--color-dark-300);

  --buttons-silent-background-color: var(--color-light-100);
  --buttons-silent-background-color-hover: var(--color-primary-200);
  --buttons-silent-border-color: var(--color-light-300);
  --buttons-silent-border-color-hover: var(--color-primary-200);
  --buttons-silent-label-color: var(--color-dark-300);
  --buttons-silent-label-color-hover: var(--color-white);

  ////////// cards
  --cards-background-color: var(--color-white);
  // --cards-background-color-highlight: var(--color-white);
  --cards-border-color: var(--color-light-300);
  --cards-text-color: var(--color-dark-100);
  // --cards-text-color-alt: var(--color-light-100);
  --cards-title-color: var(--color-dark-300);
  // --cards-title-color-alt: var(--color-white);

  ////////// input
  --form-input-background-color: var(--color-white);
  --form-input-border-color: var(--color-light-200);
  --form-input-border-color-hover: var(--color-light-300);
  --form-input-label-color: var(--color-dark-200);

  ////////// toggle
  // --form-toggle-label-color: var(--color-dark-200);
  // --form-toggle-toggle-color: var(--color-light-200);
  // --form-toggle-toggle-color-active: var(--color-dark-200);
  // --form-toggle-toggle-handle: var(--color-white);
  // --misc-aggregation-background-color: var(--color-secondary-200);
  // --misc-aggregation-color: var(--color-light-100);
  // --misc-tags-background-color: var(--color-light-100);
  // --misc-tags-background-color-hover: var(--color-light-200);
  // --misc-tags-border-color: var(--color-light-300);
  // --misc-tags-border-color-hover: var(--color-light-300);
  // --misc-tags-label-color: var(--color-dark-300);
  // --misc-tags-label-color-hover: var(--color-dark-300);

  ////////// paging buttons
  --paging-background-color: var(--color-light-100);
  --paging-background-color-hover: var(--color-white);
  --paging-border-color: var(--color-light-200);
  --paging-border-color-hover: var(--color-light-200);
  --paging-text-color: var(--color-dark-100);
  --paging-text-color-hover: var(--color-dark-300);

  ////////// table
  // --table-background-color: var(--color-white);
  // --table-background-color-hover: var(--color-light-100);
  // --table-border-color: var(--color-light-200);
  // --table-table-head-color: var(--color-dark-200);
  // --table-text-color: var(--color-dark-300);
  // --table-text-color-alt: var(--color-dark-100);
}
