.emcl-button {
  @apply text-button inline-flex h-8 flex-shrink-0 flex-grow-0 items-center justify-center gap-1 rounded-[4px] border  p-2 font-medium  transition-all duration-200 disabled:cursor-default;

  .mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}

.emcl-button-primary {
  background-color: rgb(var(--buttons-primary-background-color));
  border-color: rgb(var(--buttons-primary-background-color));
  color: rgb(var(--buttons-primary-label-color));
  &:hover {
    background-color: rgb(var(--buttons-primary-background-color-hover));
    border-color: rgb(var(--buttons-primary-background-color-hover));
  }
  @apply disabled:bg-primary-100;
}

.emcl-button-secondary {
  background-color: rgb(var(--buttons-secondary-background-color));
  border-color: rgb(var(--buttons-secondary-background-color));
  color: rgb(var(--buttons-secondary-label-color));
  &:hover {
    background-color: rgb(var(--buttons-secondary-background-color-hover));
    border-color: rgb(var(--buttons-secondary-background-color-hover));
  }
  @apply disabled:bg-secondary-100;
}

.emcl-button-accent {
  background-color: rgb(var(--buttons-accent-background-color));
  border-color: rgb(var(--buttons-accent-background-color));
  color: rgb(var(--buttons-accent-label-color));
  &:hover {
    background-color: rgb(var(--buttons-accent-background-color-hover));
    border-color: rgb(var(--buttons-accent-background-color-hover));
  }
  @apply disabled:bg-accent-100;
}

.emcl-button-silent {
  background-color: rgb(var(--buttons-silent-background-color));
  border-color: rgb(var(--buttons-silent-border-color));
  color: rgb(var(--buttons-silent-label-color));
  &:hover {
    background-color: rgb(var(--buttons-silent-background-color-hover));
    border-color: rgb(var(--buttons-silent-border-color-hover));
    color: rgb(var(--buttons-silent-label-color-hover));
  }
  @apply disabled:bg-light-100;
}

.emcl-button-paging {
  background-color: rgb(var(--paging-background-color));
  border-color: rgb(var(--paging-border-color));
  color: rgb(var(--paging-label-color));
  &:hover {
    background-color: rgb(var(--paging-background-color-hover));
    border-color: rgb(var(--paging-border-color-hover));
    color: rgb(var(--paging-label-color-hover));
  }
  @apply disabled:bg-light-100 text-xs;
}
