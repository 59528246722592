/** INPUT & SELECT ***/

.emb-input-container,
.emb-select-container {
  @apply relative block w-full;
  label {
    @apply text-label absolute left-4 top-[7px] text-gray-300;
  }
  .emb-input,
  .emb-select {
    @apply pt-[14px];
  }
}

.emb-input,
.emb-select {
  @apply border-border-default text-value focus:border-border-focus hover:border-border-hover placeholder:text-gray-400-020 disabled:text-gray-400-040  z-10 flex h-12 w-full appearance-none rounded-[8px] border bg-white py-0 pl-4  pr-4 font-normal text-opacity-[0.2] transition-all duration-200  focus:outline-none focus:ring-0 disabled:bg-gray-200;
}

.emb-select {
  @apply cursor-pointer pr-9 disabled:cursor-auto;
}

.emb-select-container::after {
  content: '';
  z-index: 20;
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 15px; /* Adjust based on your SVG size */
  height: 15px; /* Adjust based on your SVG size */
  background-image: url('../projects/embert-admin/src/assets/icons/Icon Chevron.svg');
  // background-color: red;
  background-size: cover;
  pointer-events: none;
}
